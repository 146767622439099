import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex, FlexContent } from '$components/layouts';
import { mq } from '$lib/helpers';
import { useAppEvents, useGtm } from '$hooks';
import { useAppStore } from '~/store';
import { Text } from '../text';
import { SvgIcon } from '../svg-icon';
import { Responsive } from '../responsive';

type SidePanelVariants = 'normal' | 'custom' | 'richtext';

type Props = {
    show?: boolean;
    variant?: SidePanelVariants;
    maxWidth?: number;
    title?: string;
    modalTrackType?: string;
    mobileStyle?: boolean;
    onChange?: (open: boolean) => void;
    children?: ReactNode;
};

export const SidePanel: FC<Props> = memo(
    ({ show, onChange, modalTrackType, children, title, mobileStyle, variant = 'normal', maxWidth = 650 }: Props) => {
        const { trackModalView } = useGtm();
        const appTopPadding = useAppStore((state) => state.appTopPadding);
        const { onToggleModal } = useAppEvents();
        const [modalInit, setModalInit] = useState(false);

        const onOpenChange = (open: boolean) => {
            onChange?.(open);
            if (open && modalTrackType) {
                trackModalView(modalTrackType);
            }
        };

        useEffect(() => {
            if (!modalInit) {
                setModalInit(true);
                if (show) {
                    onToggleModal(show);
                }
            } else {
                onToggleModal(show);
            }
        }, [show]);

        return (
            <RadixDialog.Root open={show} onOpenChange={onOpenChange}>
                <DialogOverlay />
                <DialogContent maxWidth={maxWidth}>
                    <DialogContentWrapper column>
                        {variant === 'custom' ? (
                            <>{children}</>
                        ) : (
                            <>
                                {mobileStyle ? (
                                    <DialogHeader appPaddingTop={appTopPadding || undefined} mobileStyle>
                                        <Responsive max="md">
                                            <DialogBack>
                                                <SvgIcon svg="arrowLeft" size={24} />
                                            </DialogBack>
                                        </Responsive>
                                        <Responsive min="md">
                                            <DialogClosePlaceholder />
                                        </Responsive>
                                        <FlexContent grow={1}>
                                            <Text p noSpacing fontWeight="Medium" variant="sidepanelHeader" center>
                                                {title}
                                            </Text>
                                        </FlexContent>
                                        <Responsive max="md">
                                            <DialogClosePlaceholder />
                                        </Responsive>
                                        <Responsive min="md">
                                            <DialogClose>
                                                <SvgIcon svg="cross" size={24} />
                                            </DialogClose>
                                        </Responsive>
                                    </DialogHeader>
                                ) : (
                                    <DialogHeader appPaddingTop={appTopPadding || undefined}>
                                        <FlexContent grow={1}>
                                            <Text h2 noSpacing variant="sidepanelHeader">
                                                {title}
                                            </Text>
                                        </FlexContent>
                                        <DialogClose>
                                            <SvgIcon svg="cross" size={24} />
                                        </DialogClose>
                                    </DialogHeader>
                                )}
                                <DialogChildrenContainer variant={variant}>{children}</DialogChildrenContainer>
                            </>
                        )}
                    </DialogContentWrapper>
                </DialogContent>
            </RadixDialog.Root>
        );
    }
);

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const contentShow = keyframes({
    '0%': { transform: 'translate(100%)' },
    '100%': { transform: 'translate(0)' },
});

const DialogOverlay = styled(RadixDialog.Overlay)({
    backgroundColor: 'rgba(0,0,0, 0.6)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
});

const DialogContent = styled(RadixDialog.Content)<{ maxWidth: number }>(({ maxWidth }) => ({
    backgroundColor: 'white',
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    maxWidth: `${maxWidth}px`,
    display: 'flex',
    flexDirection: 'column',
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,
        willChange: 'transform',
    },
    '&:focus': { outline: 'none' },
}));

// TODO: This is temporary and will be rectified by IND-8016
const DialogClose = styled(RadixDialog.Close)({
    all: 'unset',
    borderRadius: '20px',
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
});
const DialogBack = styled(RadixDialog.Close)({
    all: 'unset',
    borderRadius: '20px',
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
});
const DialogClosePlaceholder = styled.div({
    width: '25px',
    height: '25px',
});

const DialogContentWrapper = styled(Flex)({
    flex: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
});

const DialogHeader = styled.div<{ appPaddingTop?: number; mobileStyle?: boolean }>(
    ({ theme, appPaddingTop, mobileStyle }) => ({
        height: '100px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: mobileStyle ? `${theme.space[4]} ${theme.sizes.siteGutter}` : `${theme.space[9]} ${theme.space[6]}`,

        [mq('sm')]: {
            padding: mobileStyle ? `${theme.space[4]} ${theme.sizes.siteGutter}` : theme.space[9],
        },

        ...(!!appPaddingTop && { paddingTop: appPaddingTop }),
    })
);

const DialogChildrenContainer = styled.div<{ variant: SidePanelVariants }>(({ theme, variant }) => ({
    flex: 1,
    overflow: 'auto',
    padding: variant === 'richtext' ? `0 ${theme.space[6]} ${theme.space[9]}` : 0,
    display: 'flex',
    flexDirection: 'column',
    // TODO: This is temporary and will be rectified by IND-8016
    h1: { ...theme.mixins.useTextStyle('display2') },
    h2: { ...theme.mixins.useTextStyle('display3') },
    h3: { ...theme.mixins.useTextStyle('display4') },

    [mq('sm')]: {
        padding: variant === 'richtext' ? `0 ${theme.space[9]} ${theme.space[9]}` : 0,
    },
}));
